import React from "react";
import Hero from "../components/Hero2";
import { graphql, Link } from "gatsby";
import Image from "gatsby-image";
import Layout from "../components/Layout";
import Markdown from "react-markdown";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { FiTarget } from "react-icons/fi";
import Typograghy from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Seo from "../components/Seo";

//import { IconContext } from "react-icons"
import { useTheme, makeStyles } from "@material-ui/core/styles";
const useStyle = makeStyles((theme) => ({
  section: {
    ...theme.section.main,
  },

  sectionCenter: {
    ...theme.section.center,
    alignItems: "start",
  },

  sectionWhite: {
    ...theme.section.white,
  },
  bgGrey: {
    background: theme.palette.common.offWhite,
  },
}));

const ServiceTemplate = ({ data }) => {
  const classes = useStyle();
  const theme = useTheme();
  return (
    <>
      <Seo title={data.service.title} />
      <Layout>
        <main className="service-page bg-grey">
          <Hero
            title={data.service.title}
            description={data.service.subTitle}
            className="service-hero"
            backgroundColor={data.service.color.primary}
            icone={data.service.icone.svg.dataURI}
          />

          <section className="section">
            <div
              className={`section-center ${classes.sectionWhite} service-info`}
            >
              <Typograghy component="h3" variant="h4">
                Les Formats d’annonces disponible
              </Typograghy>
              <div
                className={`${
                  data.service.availableAdFormats.length > 3 ? "col-4" : "col-3"
                }`}
              >
                {" "}
                {data.service.availableAdFormats.map((element) => {
                  return (
                    <div key={element.id}>
                      <Image
                        fixed={element.image.fixed}
                        style={{
                          margin: "1rem auto",
                        }}
                      />
                      <Typograghy component="h4" variant="h5">
                        {element.title}
                      </Typograghy>
                      <Typograghy component="p" variant="body1">
                        {element.description.description}
                      </Typograghy>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
          <section className="section">
            <div
              className={`section-center ${classes.sectionWhite} service-why-network`}
            >
              <div className="col-2">
                <div>
                  <Typograghy component="h3" variant="h4">
                    {data.service.whyNetwork.titile}
                  </Typograghy>
                  <Typograghy variant="body1">
                    <Markdown
                      source={data.service.whyNetwork.description.description}
                    ></Markdown>
                  </Typograghy>
                </div>
                <div>
                  <Image fluid={data.service.whyNetwork.image.fluid} />
                </div>
              </div>
            </div>
          </section>

          <section className="section ">
            <div
              className={`section-center ${classes.sectionWhite} service-why-scalable`}
            >
              <div className="col-2">
                <div>
                  <Image fluid={data.service.whyScalable.image.fluid} />
                </div>
                <div>
                  <Typograghy component="h3" variant="h4">
                    {data.service.whyScalable.titile}
                  </Typograghy>
                  <Typograghy variant="body1">
                    <Markdown
                      source={data.service.whyScalable.description.description}
                    ></Markdown>
                  </Typograghy>
                </div>
              </div>
            </div>
          </section>

          <div className="section">
            <div
              className="section-center service-cta"
              style={{
                backgroundColor: `${data.service.color.secondary}`,
              }}
            >
              <i>
                <FaFileInvoiceDollar size={70} />
              </i>
              <div>
                <Typograghy component="h4" variant="h5">
                  Découvrez nos Tarifs dégressifs en fonction du volume
                </Typograghy>
                <Typograghy component="p">
                  Commencez votre première campagne Youtube aujourd’hui.
                </Typograghy>
              </div>

              <Link
                to="https://calendly.com/scalable-ads/45min"
                // style={{
                //   backgroundColor: "white",
                //   color: `${data.service.color.secondary}`,
                // }}
                style={{
                  fontSize: "1rem",
                  padding: "20px",
                  textAlign: "center",
                  borderRadius: "10px",
                  color: `${data.service.color.secondary}`,
                  fontWeight: 700,
                  backgroundColor: "white",
                }}
              >
                RÉSERVER UN APPEL{" "}
              </Link>
            </div>
          </div>

          <section className="section service-targeting">
            <div className={`section-center ${classes.sectionWhite} `}>
              <Typograghy component="h3" variant="h4">
                Options de Ciblage{" "}
              </Typograghy>
              <FiTarget
                size={60}
                style={{
                  backgroundColor: `${data.service.color.primary}`,
                  marginBottom: "1rem",
                  borderRadius: "50%",
                }}
                color="white"
              />
              <div className="col-2">
                {data.service.targeting.map((element) => {
                  return (
                    <div
                      className="service-targeting-item"
                      key={element.id}
                      style={{
                        backgroundColor: `${data.service.color.primary}`,
                      }}
                    >
                      <Typograghy component="h4" variant="h5">
                        {element.title}
                      </Typograghy>
                      <div style={{ paddingBottom: "10px" }}></div>
                      <Typograghy component="p">
                        {element.description.description}
                      </Typograghy>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>

          {/*
        <Description
          title={data.service.title}
          description={data.service.description.description}
        />
        <Program data={data.service.program} />
        <Info
          title={data.service.title}
          location={data.service.location.location}
          price={data.service.price}
        />
              */}

          <div className="section">
            <div
              className="section-center service-cta"
              style={{
                backgroundColor: `${data.service.color.secondary}`,
              }}
            >
              <i>
                <FaFileInvoiceDollar size={70} />
              </i>
              <div>
                <Typograghy component="h4" variant="h5">
                  Découvrez nos Tarifs dégressifs en fonction du volume
                </Typograghy>
                <Typograghy component="p">
                  Commencez votre première campagne Youtube aujourd’hui.
                </Typograghy>
              </div>

              <Button
                variant="contained"
                component={Link}
                to="https://calendly.com/scalable-ads/45min"
                style={{
                  fontSize: "1rem",
                  textAlign: "center",
                  color: `${data.service.color.secondary}`,
                  fontWeight: 700,
                  backgroundColor: "white",
                }}
              >
                RÉSERVER UN APPEL{" "}
              </Button>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export const query = graphql`
  query GetSingleService($slug: String) {
    service: contentfulServices(slug: { eq: $slug }) {
      targeting {
        title
        id
        description {
          description
        }
      }
      icone {
        svg {
          dataURI
        }
      }
      availableAdFormats {
        title
        id
        description {
          description
        }
        image {
          fixed(height: 80) {
            ...GatsbyContentfulFixed_tracedSVG
          }
        }
      }

      whyNetwork {
        titile
        description {
          description
        }
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }

      whyScalable {
        titile
        description {
          description
        }
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }

      color {
        primary
        secondary
      }

      subTitle
      slug
      title
    }
  }
`;

export default ServiceTemplate;
